import { attr, belongsTo } from '@ember-data/model';

import Subject from 'qonto/models/subject';

export default class NrcPaymentModel extends Subject {
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  @belongsTo('organization', { async: false, inverse: null }) organization;

  @attr('string') canceledAt;
  @attr('string') declineReason;
  @attr('string') executionDate;
  @attr('string') nrc;
  @attr('string') receiptUrl;
  @attr('string') status;
  @attr('string') statusGroup;
  @attr('string') taxCode;
  @attr('string') taxDescription;
  @attr('string') type;
  @attr amount;

  get avatarInfo() {
    let activityTagSVG = `/icon/category/tax-m.svg`;

    return {
      mediumLogo: activityTagSVG,
      smallLogo: activityTagSVG,
      hasBorder: false,
    };
  }
}
