import Model, { attr, belongsTo } from '@ember-data/model';
import { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import { service } from '@ember/service';
import { isNone } from '@ember/utils';

import CURRENCIES from 'qonto/constants/currencies';
import { DEFAULT_VAT_RATE_PER_COUNTRY } from 'qonto/constants/receivable-invoice';
import { limitNumberOfNewLines, multiplyAndRound } from 'qonto/utils/receivable-invoicing';

export function defaultValues(ownerOrOwned, vatRate, vatExemptionCode) {
  let owner = getOwner(ownerOrOwned) || ownerOrOwned;
  let organizationManager = owner.lookup('service:organization-manager');

  let defaultVatRate;
  if (isNone(vatRate)) {
    defaultVatRate = DEFAULT_VAT_RATE_PER_COUNTRY[organizationManager.organization?.legalCountry];
  } else {
    defaultVatRate = String(vatRate);
  }

  let lastVatExemptionCode;
  if (vatExemptionCode !== undefined) {
    lastVatExemptionCode = vatExemptionCode;
  }

  return {
    vatRate: defaultVatRate,
    vatExemptionCode: lastVatExemptionCode,
  };
}

export default class ReceivableInvoiceItemModel extends Model {
  @service intl;
  @service organizationManager;

  @attr title;
  @attr description;
  @attr('string') quantity;
  /** @type {string | null} */
  @attr unit;
  @attr('string') unitPrice;
  @attr('string') vatRate;
  @attr('hash') discount;
  @attr vatExemptionCode;
  @attr('string', { defaultValue: CURRENCIES.default }) currency;
  /** @type "good" | "service" | null **/
  @attr('string') type;
  /** @type Array<{ title: string, url: string }> **/
  @attr links;
  /** @type {string | null} */
  @attr productId;

  @belongsTo('receivableInvoice', { async: true, inverse: 'items' }) receivableInvoice;
  @belongsTo('selfInvoice', { async: true, inverse: 'items' }) selfInvoice;
  @belongsTo('quote', { async: true, inverse: 'items' }) quote;
  @belongsTo('invoiceSubscription', { async: true, inverse: 'items' }) invoiceSubscription;

  get totalAmount() {
    return (
      parseFloat(this.totalExcludingVat) +
      parseFloat(this.totalVat) -
      parseFloat(this.totalDiscount)
    ).toFixed(2);
  }

  @action
  updateDescription(description) {
    this.description = limitNumberOfNewLines(description, 10);
  }

  get totalVat() {
    let total = this.totalExcludingVat - this.totalDiscount;
    return multiplyAndRound(this.vatRate, total, 100).toFixed(2);
  }

  /*
    Sometimes it's needed with 2 when displaying the value, 
    sometimes with 3 decimals when handling internal calculations
  */
  get totalExcludingVatWith3Decimals() {
    return this.unitPrice && this.quantity
      ? multiplyAndRound(parseFloat(this.quantity), parseFloat(this.unitPrice), 1000).toFixed(3)
      : '0.00';
  }

  get totalExcludingVatWith2Decimals() {
    return this.unitPrice && this.quantity
      ? multiplyAndRound(parseFloat(this.quantity), parseFloat(this.unitPrice), 100).toFixed(2)
      : '0.00';
  }

  /*
    Rounding the float value is required to avoid imprecise decimals rounding
    When there is a 5 in the third decimal position, JS will round down instead of up
    Example: 0.145  will be parsed as 0.14, when instead the rounded value wanted is 0.15
  */
  get totalExcludingVat() {
    return this.totalExcludingVatWith2Decimals;
  }

  get totalDiscountPercentageWith3Decimals() {
    return this.totalExcludingVat && this.discount?.value
      ? multiplyAndRound(this.discount.value, this.totalExcludingVat, 1000).toFixed(3)
      : '0';
  }

  get totalDiscountPercentageWith2Decimals() {
    return this.totalExcludingVat && this.discount?.value
      ? multiplyAndRound(this.discount.value, this.totalExcludingVat, 100).toFixed(2)
      : '0';
  }

  get totalDiscount() {
    if (this.discount?.type === 'absolute') {
      return (this.discount.value * Math.sign(this.quantity) * Math.sign(this.unitPrice)).toFixed(
        2
      );
    } else if (this.discount?.type === 'percentage') {
      return this.totalDiscountPercentageWith2Decimals;
    } else {
      return '0';
    }
  }

  // discountedTotalExcludingVat = totalExcludingVat - discount
  get discountedTotalExcludingVat() {
    if (this.discount?.type === 'percentage') {
      let totalExcludingVat = this.totalExcludingVatWith3Decimals;

      let totalDiscount = this.totalDiscountPercentageWith3Decimals;

      return (parseFloat(totalExcludingVat) - parseFloat(totalDiscount)).toFixed(2);
    }

    return this.discount?.type === 'absolute'
      ? (parseFloat(this.totalExcludingVat) - parseFloat(this.totalDiscount)).toFixed(2)
      : this.totalExcludingVat;
  }
}
