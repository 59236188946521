import { ForbiddenError, NotFoundError } from '@ember-data/adapter/error';
import Model, { attr, belongsTo } from '@ember-data/model';
import { decamelize, underscore } from '@ember/string';
import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';
import dayjs from 'dayjs';

const INITIAL_TRIAL_UPSELL_CUTOFF_DAYS = 1;
export default class OrganizationSubscriptionModel extends Model {
  @attr('string') pricePlanCode;
  @attr('string') recurrenceOn;
  @attr('string') status;
  @attr('string') nextRecurrenceDate;
  @attr('string') nextSubscriptionBillingDate;
  @attr('string') nextInvoicingDate;
  @attr('string') recurrence;
  @attr nextBillingDate;
  @attr activeTrial;
  @attr trialByFeatures;
  @attr('hash') availableTrials;
  @attr pricePlanAnnualPriceVatIncluded;
  @attr pricePlanMonthlyPriceVatIncluded;

  @belongsTo('organization', { async: true, inverse: null }) organization;
  @belongsTo('pricePlan', { async: true, inverse: null }) pricePlan;

  modelName = 'subscription';

  get activeTrialRemainingDays() {
    let { activeTrial } = this;
    if (!activeTrial) {
      return 0;
    }
    // Compare dates by day and hours, ex: when less than 23 hours differenceInDays will return 0
    let endDate = dayjs(activeTrial.end_date).endOf('day');
    let today = dayjs().startOf('hour');
    return endDate.diff(today, 'day');
  }

  get hasInitialTrial() {
    let { activeTrial } = this;

    if (!activeTrial) {
      return false;
    }

    return !activeTrial.previous_plan_id && !activeTrial.previous_recurrence;
  }

  get hasInitialTrialUpsell() {
    return this.hasInitialTrial && this.activeTrialRemainingDays > INITIAL_TRIAL_UPSELL_CUTOFF_DAYS;
  }

  @waitFor
  async confirmFlight() {
    let { organization_id, price_plan_id, recurrence } = this.serialize();
    let data = { subscription: { organization_id, price_plan_id, recurrence } };
    return await apiAction(this, { method: 'POST', path: 'confirm', data });
  }

  @waitFor
  async upgradeRecommendation(extra) {
    extra = extra ? decamelize(extra) : extra;

    let response = await apiAction(this, {
      method: 'GET',
      path: 'price_plans/upgrade_recommendation',
      data: { extra },
    });
    return response.price_plan;
  }

  findTrial(feature) {
    let id = this.trialByFeatures?.[underscore(feature)];
    if (!id) {
      return null;
    }

    return this.availableTrials?.find(({ pricePlanId }) => pricePlanId === id) || null;
  }

  @waitFor
  async hasUpgrade(feature) {
    try {
      await this.upgradeRecommendation(feature);
    } catch (error) {
      if (error instanceof NotFoundError || error instanceof ForbiddenError) {
        return false;
      }

      throw error;
    }

    return true;
  }
}
