import Model, { attr } from '@ember-data/model';

import { equal } from 'macro-decorators';

import { FINANCING_TRANSFER_STATUS } from 'qonto/constants/financing';

export default class FinancingModel extends Model {
  @attr('string') status;
  @attr('string') activityTag;
  @attr('string') beneficiaryName;
  @attr('string') repaymentStatus;
  @attr('string') bankAccountName;
  @attr('number') paidInstallments;
  @attr('number') totalInstallments;
  @attr('date') nextInstallmentAt;
  @attr('date') completedAt;
  @attr beneficiaryLogo;
  @attr nextInstallmentTotalAmount;
  @attr financedAmount;
  @attr totalAmount;

  @equal('status', FINANCING_TRANSFER_STATUS.PROCESSING) isProcessing;
  @equal('status', FINANCING_TRANSFER_STATUS.DECLINED) isDeclined;

  get avatarInfo() {
    let avatarStatusIconSrc = null;
    let activityTagSVG = `/icon/category/${this.activityTag}-m.svg`;

    if (this.isProcessing) {
      avatarStatusIconSrc = 'status_processing-s';
    }
    if (this.isDeclined) {
      avatarStatusIconSrc = 'status_stop-xs';
    }

    return {
      mediumLogo: this.beneficiaryLogo?.medium ?? activityTagSVG,
      smallLogo: this.beneficiaryLogo?.small ?? activityTagSVG,
      hasBorder: Boolean(this.beneficiaryLogo?.medium),
      icon: avatarStatusIconSrc,
    };
  }
}
