import Model, { attr } from '@ember-data/model';
import { service } from '@ember/service';
import { camelize } from '@ember/string';

export default class PricePlan extends Model {
  @service localeManager;

  @attr('number') accountantLimit;
  @attr('string') code;
  @attr('string') groupCode;
  /** @type number | {{ value: string, currency: string}} */
  @attr monthlyPrice;
  /** @type {{ value: string, currency: string}} */
  @attr annualPrice;
  @attr('number') physicalCardLimit;
  @attr('number') virtualCardLimit;
  @attr('number') userLimit;
  @attr('number') sepaOutLimit;
  @attr('number') checkLimit;
  @attr('number') bankAccountLimit;
  @attr('number') receivableInvoiceGlobalLimit;
  @attr brandName;
  @attr features;
  @attr lineup;
  @attr options;
  @attr('boolean') disabled;

  get optionsObject() {
    return this.options.reduce((acc, { price, code }) => {
      acc[camelize(code)] = price;
      return acc;
    }, {});
  }

  get featuresObject() {
    return this.features?.reduce((acc, name) => {
      acc[camelize(name)] = true;
      return acc;
    }, {});
  }

  get localName() {
    return this.brandName[this.localeManager.locale];
  }
}
