import Model, { attr } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

import CURRENCIES from 'qonto/constants/currencies';

export default class DocumentItemModel extends Model {
  @attr('string') title;
  @attr('string') description;
  @attr('string') unit;
  @attr('string') unitPrice;
  @attr('string') vatRate;
  @attr('number') totalDocuments;
  @attr('string') vatExemptionCode;
  @attr('string', { defaultValue: CURRENCIES.default }) currency;

  @attr change;

  @tracked isSelected = false;
}
