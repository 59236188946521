import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

import dayjs from 'dayjs';

import {
  CERTIFICATION_DURATION_DAYS,
  COMPANY_REPORT_STATUS,
} from 'qonto/constants/kyc-kyb-update-process';

export default class KycKybUpdateProcess extends Model {
  /** @type {KycKybUpdateMembershipChangeRequest[]} */
  @hasMany('kycKybUpdateMembershipChangeRequest', { async: true, inverse: 'kycKybUpdateProcess' })
  kycKybUpdateMembershipChangeRequests;

  /** @type {KycKybUpdateOrganizationChangeRequest | null} */
  @belongsTo('kycKybUpdateOrganizationChangeRequest', {
    async: true,
    inverse: 'kycKybUpdateProcess',
  })
  kycKybUpdateOrganizationChangeRequest;

  /** @type {Organization} */
  @belongsTo('organization', { async: true, inverse: 'ongoingKycKybUpdateProcess' }) organization;

  /** @type {File} */
  @belongsTo('file', { async: true, inverse: null }) kycKybUpdateKbisFile;

  /** @type {File} */
  @belongsTo('file', { async: true, inverse: null }) kycKybUpdateBylawFile;

  /** @type {Date} */
  @attr('date') createdAt;

  /** @type {boolean} */
  @attr legallyRequired;

  /** @type {boolean} */
  @attr needsReview;

  /** @type {'created' | 'pending_review' | 'accepted' | 'rejected'} */
  @attr status;

  /** @type {'unknown' | 'found' | 'not_found' | null} */
  @attr({ defaultValue: null }) companyReportStatus;

  get isCompanyReportStatusNull() {
    return this.companyReportStatus === null;
  }

  get isCompanyReportStatusNotFound() {
    return this.companyReportStatus === COMPANY_REPORT_STATUS.NOT_FOUND;
  }

  get isCompanyReportStatusFound() {
    return this.companyReportStatus === COMPANY_REPORT_STATUS.FOUND;
  }

  get isEditable() {
    return this.status === 'created';
  }

  get inReview() {
    return this.status === 'pending_review';
  }

  get isAccepted() {
    return this.status === 'accepted';
  }

  get hasChanges() {
    return (
      this.kycKybUpdateOrganizationChangeRequest?.get('isNew') === false ||
      this.kycKybUpdateMembershipChangeRequests.content?.some(it => !it.isNew)
    );
  }

  get isDuringCertificationTime() {
    let certificationDate = dayjs(this.createdAt).add(CERTIFICATION_DURATION_DAYS, 'day');

    let endOfToday = dayjs().endOf('day');

    return (
      certificationDate.isAfter(endOfToday) &&
      this.status === 'created' &&
      this.legallyRequired &&
      (this.isCompanyReportStatusNull || this.isCompanyReportStatusNotFound)
    );
  }
}
