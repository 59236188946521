import Model, { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import CURRENCIES from 'qonto/constants/currencies';
import { NetworkManagerError } from 'qonto/services/network-manager';
import { errorsArrayToHash } from 'qonto/utils/errors-array-to-hash';

export default class BaseInvoicingDocumentModel extends Model {
  @service networkManager;

  /** @type {string} */
  @attr('string', { defaultValue: CURRENCIES.default }) currency;
  /** @type {string} */
  @attr locale;
  /** @type {string} */
  @attr purchaseOrder;
  /** @type {string} */
  @attr beneficiaryName;
  /** @type {string} */
  @attr contactEmail;
  /** @type {string} */
  @attr termsAndConditions;
  /** @type {string} */
  @attr stampDutyAmount;
  /** @type {string} */
  @attr header;
  /** @type {string} */
  @attr footer;
  /** @type {string} */
  @attr amountDue;
  /** @type {Date} */
  @attr updatedAt;
  /** @type {Date} */
  @attr createdAt;
  /** @type {string} */
  @attr status;

  /** @type {Payment} */
  @belongsTo('receivable-invoice/payment', { async: false, inverse: null }) payment;
  /** @type {WelfareFund} */
  @belongsTo('receivable-invoice/welfare-fund', { async: false, inverse: null }) welfareFund;
  /** @type {WithholdingTax} */
  @belongsTo('receivable-invoice/withholding-tax', { async: false, inverse: null }) withholdingTax;

  /** @type {Customer} */
  @belongsTo('client-hub', { async: true, inverse: null }) customer;
  /** @type {Organization} */
  @belongsTo('organization', { async: false, inverse: null }) organization;
  /** @type {BankAccount} */
  @belongsTo('bankAccount', { async: true, inverse: null }) bankAccount;

  // pdfPreviewIframeUrl is not sent to BE, it should be set to null as a tracked property
  @tracked pdfPreviewIframeUrl = null;

  @waitFor
  async save() {
    try {
      return await super.save(...arguments);
    } catch (error) {
      if (error.isAdapterError) {
        this._assignRelationshipErrors(error);
      }
      throw error;
    }
  }

  clearItemsWithNoId() {
    this.items.filter(item => item.get('id') === null).forEach(item => item.deleteRecord());
  }

  _handleError(error) {
    if ((error.isAdapterError || error instanceof NetworkManagerError) && error.errors) {
      let errors = errorsArrayToHash(error.errors, { useErrorCode: true });
      this.networkManager.errorModelInjector(this, errors);
    }

    if (error.isAdapterError || error instanceof NetworkManagerError) {
      this._assignRelationshipErrors(error);
    }

    throw error;
  }

  _assignRelationshipErrors(error) {
    // reduce
    // [
    //   { attribute: "items/0/title", message: "required" }
    //   { attribute: "items/1/title", message: "required" }
    // ]
    // to
    // {
    //   0: { "title": ["required"] }
    //   1: { "title": ["required"] }
    // }
    // then assign to items
    let parsedErrors = this.errors.reduce((errorsForItems, error) => {
      let [invoiceAttr, index, attribute] = error.attribute.split('/');
      if (invoiceAttr === 'items') {
        error = { attribute, message: error.message };
        errorsForItems[index] = errorsForItems[index] || {};
        errorsForItems[index][attribute] = errorsForItems[index][attribute] || [];
        errorsForItems[index][attribute].push(error.message);
      }
      return errorsForItems;
    }, {});

    let parsedRelationshipErrors = this.errors.reduce(
      (errorsForRelationships, relationshipError) => {
        let [invoiceAttr, attribute] = relationshipError.attribute.split('/');
        if (
          invoiceAttr === 'payment' ||
          invoiceAttr === 'welfareFund' ||
          invoiceAttr === 'withholdingTax'
        ) {
          errorsForRelationships[invoiceAttr] = errorsForRelationships[invoiceAttr] || {};
          errorsForRelationships[invoiceAttr][attribute] =
            errorsForRelationships[invoiceAttr][attribute] || [];
          errorsForRelationships[invoiceAttr][attribute].push(relationshipError.message);
        }
        return errorsForRelationships;
      },
      {}
    );

    Object.entries(parsedErrors).forEach(([index, parsedErrorsForItem]) => {
      this.networkManager.errorModelInjector(this.items[index], parsedErrorsForItem, error);
    });

    Object.entries(parsedRelationshipErrors).forEach(([attribute, parsedRelationshipError]) => {
      if (attribute === 'payment') {
        this.networkManager.errorModelInjector(this.payment, parsedRelationshipError, error);
      }
      if (attribute === 'welfareFund') {
        this.networkManager.errorModelInjector(this.welfareFund, parsedRelationshipError, error);
      }
      if (attribute === 'withholdingTax') {
        this.networkManager.errorModelInjector(this.withholdingTax, parsedRelationshipError, error);
      }
    });
  }

  get totalAmount() {
    let totalAmount =
      parseFloat(this.totalVat) +
      parseFloat(this.totalExcludingVat) +
      parseFloat(this.welfareFundAmount) -
      parseFloat(this.withholdingTaxAmount);
    return totalAmount.toFixed(2);
  }

  get totalVatWithoutWelfareFund() {
    return this.items.reduce((total, item) => {
      return parseFloat(total) + parseFloat(item.totalVat);
    }, 0);
  }

  get totalVat() {
    return (
      parseFloat(this.totalVatWithoutWelfareFund) + parseFloat(this.welfareFundVatAmount)
    ).toFixed(2);
  }

  get welfareFundVatAmount() {
    return this.welfareFund?.rate
      ? (parseFloat(this.totalVatWithoutWelfareFund) * parseFloat(this.welfareFund.rate)).toFixed(2)
      : '0.00';
  }

  get totalExcludingVat() {
    return this.items
      .reduce((total, item) => {
        return parseFloat(total) + parseFloat(item.discountedTotalExcludingVat);
      }, 0)
      .toFixed(2);
  }

  get welfareFundAmount() {
    return this.welfareFund?.rate
      ? (parseFloat(this.totalExcludingVat) * parseFloat(this.welfareFund.rate)).toFixed(2)
      : '0.00';
  }

  get withholdingTaxAmount() {
    if (!this.withholdingTax?.rate) return '0.00';

    if (this.welfareFund?.type === 'TC22')
      return (
        (parseFloat(this.totalExcludingVat) + parseFloat(this.welfareFundAmount)) *
        parseFloat(this.withholdingTax.rate)
      ).toFixed(2);

    return (parseFloat(this.totalExcludingVat) * parseFloat(this.withholdingTax.rate)).toFixed(2);
  }
}
